// const _url = 'http://192.168.10.12:8085'

export default {
  BASE_URL: 'https://api.1nikkah.com/api/v1/',
  // BASE_URL: 'https://api-stage.1nikkah.com/api/v1/',
  // BASE_URL: _url + '/api/v1/',
  END_POINT: {
    login: 'admin/login',
    signup: 'admin/signup',
    forgot: 'admin/forgot',
    reset: 'admin/reset',
    verify: 'verify',
    logout: 'logout',
    dashboard: 'admin/dashboard',
    contactus: 'admin/contactus',
    adminprofile: 'admin/profile',
    registration: 'registration',
    appusers: 'admin/app-users/',
    adminSetting: 'admin/setting',
    adminusers: 'admin/users/',
    masterdata: 'master-data/',
    reportdata: 'report-data',
    reportusers: 'reports/users',
    blockusers: 'admin/block-users',
    notification: 'notification/',
    sendnotification: 'send-notification',
    notificationsend: 'notification/send',
    bankdetails: 'bank-details/',
    getpackages: 'getpackages',
    addpackages: 'addpackages',
    updatepackages: 'updatepackages/',
    deletepackages: 'deletepackages/',
    paymenthistory: 'payment-history',
    mysubscription: 'mysubscription',
    packageusers: 'package/users/',
    specialpackageusers: 'package/users-special/',
    adspackageusers: 'package/users-ads/',
    packagehistory: 'package/history',
    packagehistoryspecial: 'package/history-special',
    packagehistoryads: 'package/history-ads',
    packageapproval: 'package/approval',
    packageapprovalspecial: 'package/approval-special',
    packageapprovalads: 'package/approval-ads',
    countries: 'countries',
    states: 'states/',
    cities: 'cities/',
    getSpecialUsers: 'special/list/',
    createSpecialUser: 'special/create/',
    deleteSpecialUser: 'special/delete/',
    updateSpecialUser: 'special/update/',
  },
}
